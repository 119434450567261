<template>
  <div class="m">
    <div class="nav-box">
      <div class="nav-left"></div>
      <div class="nav-right">
        <van-popover
          v-model="showPopover"
          trigger="click"
          placement="bottom-end"
          :actions="actions"
          @select="onSelect"
        >
          <template #reference>
            <span
              type="primary"
              style="
                color: #000000;
                font-size: 17px;
                font-family: 'GenSenRoundedTW-M';
              "
            >
              {{ lan }}
            </span>
          </template>
        </van-popover>
      </div>
    </div>
    <div style="height: 44px"></div>
    <!-- 轮播图 -->
    <div class="detali-box-carousel">
      <el-carousel
        :autoplay="false"
        :interval="3000"
        arrow="always"
        ref="slideCarousel"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <!-- {{ item }} -->
          <el-image
            @click="bannerItemBtnFn(item.jump_url)"
            fit="cover"
            class="detali-box-carousel-img"
            :src="item.img_url"
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 分类跳转 -->
    <div class="cfToBox">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="cfToBox-item"
        @click="itemBtnFn(item.id)"
      >
        <div v-if="item.descript || item.img_url" class="cfToBox-item-img">
          <img :src="item.img_url" alt="" />
        </div>
        <div v-if="item.descript" class="cfToBox-item-txt">
          {{ item.descript }}
        </div>
      </div>
    </div>

    <CBottom type="home" :lanPam="lanPam"></CBottom>
  </div>
</template>

<script>
import { reloadMessage } from "@/lang";
import { homeLanList } from "@/utils/public_data";
import { getBanner, getProductList2, getBottomSetup } from "@/api/hfIndex";
import CBottom from "./Component_bottom.vue";
export default {
  data() {
    return {
      list: [],
      bannerList: [],
      logo: require("@/assets/images/customer/MB-home.png"),
      lanLogo: require("@/assets/images/customer/PC_line.png"),
      lanList: homeLanList(),
      lanListPam: {
        zh: "sc",
        zh_tw: "tc",
        en: "en",
        ja: "jp",
        ko: "kr",
      },
      showPopover: false,
      screenWidth: document.body.clientWidth,
      actions: [
        { text: "日本語", lan: "ja" },
        { text: "简体中文", lan: "zh" },
        { text: "繁體中文", lan: "zh_tw" },
        { text: "English", lan: "en" },
        { text: "한국어", lan: "ko" },
      ],
    };
  },
  created() {
    this.getBannerFn();
    this.getProductListFn();
  },
  mounted() {
    this.slideBanner();
    // document.querySelector('.nav-right').style.marginLeft = `${this.screenWidth - this.screenWidth * 0.69}px`
  },
  components: { CBottom },
  computed: {
    lanPam() {
      return this.lanListPam[localStorage.getItem("locale")] || "tc";
    },
    lan() {
      return this.lanList[localStorage.getItem("locale")] || "繁體中文";
    },
  },
  methods: {
    bannerItemBtnFn(e) {
      // window.location.href(你的外部链接地址);
      if (!e) return;
      window.open(e, "_blank");
    },
    async getProductListFn() {
      let res = await getProductList2(this.lanPam);
      this.list = res.data.data.list;
      console.log("list", this.list);
    },
    async getBannerFn() {
      console.log("语言", this.lanPam);
      let res = await getBanner(this.lanPam);
      this.bannerList = res.data.data;
      console.log("banner", this.bannerList);
    },
    itemBtnFn(id) {
      this.$router.push(`/hfPage_nav?id=${id}`);
    },
    // 滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector(".el-carousel__container");
      var startPointX = 0;
      var stopPointX = 0;
      //重置坐标
      var resetPoint = function () {
        startPointX = 0;
        stopPointX = 0;
      };
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX;
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX;
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this;
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint();
          return;
        }
        if (startPointX - stopPointX > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }
        if (startPointX - stopPointX < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },

    pageHandle(path, params) {
      if (params) {
        this.$router.push({ path, query: { search: JSON.stringify(params) } });
      } else {
        this.$router.push(path);
      }
    },
    onSelect(action) {
      console.log("action", action);
      this.$i18n.locale = action.lan;
      localStorage.setItem("locale", action.lan);
      reloadMessage();
      this.$router.go(0);
    },
  },
};
</script>

<style lang="scss" scoped>
.m {
  height: 100vh;
  max-width: 766px;
  margin: 0 auto;
}
.nav-box {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  .nav-left {
    height: 44px;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  .nav-right {
    height: 44px;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
}

// 走马灯样式
.detali-box-carousel {
  height: 62.4vw;
  width: 100%;
  .detali-box-carousel-img {
    width: 100%;
    height: 100%;
  }
}
/deep/.el-carousel__container {
  // height: 322px;

  .el-carousel__item {
    // background-color: aqua;
  }
}
/deep/.el-carousel__arrow {
  //   background-color: rgba(255, 255, 255, 0.5);
  display: none;
}
/deep/.el-carousel {
  // overflow: unset;
  height: 100%;
  .el-carousel__container {
    height: 56.5333vw;
  }
  .el-carousel__indicators {
    bottom: 1.3333vw;
    .el-carousel__indicator {
      padding: 0.8vw;

      .el-carousel__button {
        width: 1.6vw;
        height: 1.6vw;
        border-radius: 50%;
        background-color: #de6460;
      }
    }
  }
}

.cfToBox {
  padding: 0 16px;
  .cfToBox-item {
    margin-bottom: 5.3333vw;
    .cfToBox-item-img {
      height: 32.6667vw;
      max-height: 245px;
      // filter: drop-shadow(0 3px 4px #00000029);
      border-radius: 6px;
      background-color: #f2f2f2;
      margin-bottom: 5px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .cfToBox-item-txt {
      font-size: 3.2vw;
      line-height: 3.7333vw;
    }
  }
}

@media (min-width: 766px) {
  .detali-box-carousel {
    height: 468px;
  }
  /deep/.el-carousel {
    .el-carousel__container {
      height: 424px;
    }
    .el-carousel__indicators {
      bottom: 10px;
      .el-carousel__indicator {
        padding: 6px;

        .el-carousel__button {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .btmBox {
    padding: 40px 0 58px;
    font-size: 20px;
    p {
      margin-bottom: 4px;
    }
  }

  .cfToBox {
    padding: 0 16px;
    .cfToBox-item {
      margin-bottom: 40px;
      .cfToBox-item-img {
        height: 245px;
        border-radius: 6px;
      }
      .cfToBox-item-txt {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
</style>
